<template>
  <div
    class="account"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="title">
      <el-input
        type="text"
        placeholder="登录名关键字"
        style="width: 150px; margin-left: 10px"
        v-model="Keyword"
      >
      </el-input>
      <!-- <div class="block">
                <el-date-picker
                    v-model="LoginBeginTime"
                    type="datetime"
                    placeholder="选择最后登录的开始时间"
                >
                </el-date-picker>
            </div>
            <div class="block">
                <el-date-picker
                    v-model="LoginEndTime"
                    type="datetime"
                    placeholder="选择最后登录的结束时间"
                >
                </el-date-picker>
            </div> -->
      <el-button type="primary" icon="el-icon-search" @click="searchClick"
        >搜索</el-button
      >
      <!-- <el-button type="primary" @click="addUser">添加用户</el-button> -->
    </div>
    <!-- 表格 -->
    <div class="table">
      <el-table
        :data="Userlist"
        border
        height="600px"
        style="width: 100%;"
        @selection-change="handleSelectionChange"
        @filter-change="filterChange"
        :header-cell-style="{ background: '#FAFAFA' }"
      >
        <el-table-column type="index" column-key="id" width="45">
        </el-table-column>
        <el-table-column
          prop="nickName"
          label="昵称"
          min-width="90"
        ></el-table-column>
        <el-table-column
          prop="creationTime"
          label="注册时间"
          min-width="90"
        ></el-table-column>
        <el-table-column
          prop="identifyCount"
          label="可识别次数"
          min-width="70"
        ></el-table-column>
        <el-table-column label="状态" min-width="50">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              active-color="#13ce66"
              inactive-color="#999"
              @change="
                statusChange(scope.row.id, scope.row.status, scope.$index)
              "
            >
            </el-switch>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="210" fixed="right">
          <template slot-scope="scope">
            <el-button
              plain
              type="primary"
              size="small"
              style="margin-left: 1px"
              @click="ResetPassword(scope.row.id)"
              >重置密码</el-button
            >
            <el-button
              v-show="roleInfo.some((v) => v.roleTypeInfo === 4)"
              plain
              type="primary"
              size="small"
              style="margin-left: 15px"
              @click="GetUserDetail(scope.row.id)"
              >充值次数</el-button
            >
            <!-- <el-button
                plain
                icon="el-icon-delete"
                type="danger"
                size="small"
                style="margin-left: 1px"
                @click="UserDelete(scope.row.id)"
                >删除</el-button
            > -->
            <!-- <el-button
              plain
              type="primary"
              size="small"
              style="margin-left: 1px"
              @click="eidtUser(scope.row.id)"
              >编辑</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 页码 -->
      <div class="paging">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="PageIndex"
          :page-sizes="[25, 50, 75, 100]"
          :page-size="PageSize"
          prev-text="上一页"
          next-text="下一页"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="Totals"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 编辑弹出层 -->
    <el-dialog
      title="修改用户信息"
      :close-on-click-modal="false"
      :visible.sync="editDialogFormVisible1"
    >
      <el-form :model="editUserList">
        <el-form-item label="姓名" :label-width="formLabelWidth">
          <el-input
            v-model="editUserList.realName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="登录名" :label-width="formLabelWidth">
          <el-input
            v-model="editUserList.userName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="角色" :label-width="formLabelWidth">
          <el-select
            v-model="editUserList.roleIds"
            placeholder="请选择角色"
            filterable
            multiple
            clearable
            @change="roleIdChang"
          >
            <el-option
              v-for="item in roles"
              :key="item.id"
              :label="item.roleName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属机构" :label-width="formLabelWidth">
          <el-select
            v-model="editUserList.organisationCode"
            placeholder="请选择机构"
            filterable
            clearable
          >
            <el-option
              v-for="item in OrganistationList"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <div class="dialog-footer" style="text-align: right">
            <el-button @click="editDialogFormVisible1 = false">取 消</el-button>
            <el-button type="primary" @click="onConfirm(editUserList.id)"
              >确 定</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 充值弹出层 -->
    <el-dialog
      title="修改用户信息"
      :close-on-click-modal="false"
      :visible.sync="editDialogFormVisible"
    >
      <el-form :model="editform">
        <el-form-item label="填充次数" :label-width="formLabelWidth">
          <el-input
            v-model="editform.rechargeCount"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="dialog-footer" style="text-align: right">
            <el-button @click="editDialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="EditUserInfo(editform.id)"
              >确 定</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 添加用户 -->
    <el-dialog
      title="增加用户信息"
      :close-on-click-modal="false"
      :visible.sync="addDialogFormVisible"
    >
      <el-form :model="addform" ref="addform" :rules="addformRules">
        <el-form-item
          label="姓名"
          prop="realName"
          :label-width="formLabelWidth"
        >
          <el-input v-model="addform.realName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="登录名"
          prop="userName"
          :label-width="formLabelWidth"
        >
          <el-input v-model="addform.userName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="密码"
          prop="passWord"
          :label-width="formLabelWidth"
        >
          <el-input
            type="password"
            v-model="addform.passWord"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="确认密码"
          prop="ConfirmPassword"
          :label-width="formLabelWidth"
        >
          <el-input
            type="password"
            v-model="addform.ConfirmPassword"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="角色" :label-width="formLabelWidth">
          <el-select
            v-model="addform.roleTypeInfo"
            placeholder="请选择角色"
            filterable
            clearable
          >
            <el-option
              v-for="item in roles"
              :key="item.roleTypeInfo"
              :label="item.roleName"
              :value="item.roleTypeInfo"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属机构" :label-width="formLabelWidth">
          <el-select
            v-model="addform.organisationCode"
            placeholder="请选择机构"
            filterable
            clearable
          >
            <el-option
              v-for="item in OrganistationList"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button @click="cancelAddUser">取 消</el-button>
        <el-button type="primary" @click="confirmAddUser">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { validConfirmPassword } from "@/utils/validate.js";
import { mapState } from "vuex";
export default {
  data() {
    const validateConfirmPassword = (rule, value, callback) => {
      console.log();
      validConfirmPassword(value, this.addform.passWord)
        ? callback()
        : callback(new Error("密码不一致"));
    };
    return {
      roleInfo: "",
      roles: [],
      //   增加用户表单
      addform: {
        realName: "",
        userName: "",
        passWord: "",
        organisationCode: "",
        ConfirmPassword: "",
        roleTypeInfo: "",
      },
      addformRules: {
        realName: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        userName: [
          {
            required: true,
            message: "请输入登录名",
            trigger: "blur",
          },
        ],
        passWord: [
          {
            min: 6,
            max: 15,
            message: "长度为6-15字符串",
            trigger: "blur",
          },
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
        ConfirmPassword: [
          {
            required: true,
            message: "请输入确认密码",
            trigger: "blur",
          },
          {
            validator: validateConfirmPassword,
            trigger: "blur",
            message: "密码不一致",
          },
        ],
      },
      addDialogFormVisible: false, // 添加用户
      //   修改用户表单
      editUserList: {
        id: "",
        realName: "",
        organisationCode: "",
        roleIds: [],
      },
      editDialogFormVisible1: false,
      loading: false,
      userlist: [],
      /* 用户分页 */
      PageIndex: 1,
      PageSize: 25,
      Totals: 0,
      userid: "",
      formLabelWidth: "120px",
      rules: {
        realName: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        userName: [
          {
            required: true,
            message: "请输入登录名",
            trigger: "blur",
          },
        ],
        passWord: [
          {
            min: 6,
            max: 15,
            message: "长度为6-15字符串",
            trigger: "blur",
          },
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
        ConfirmPassword: [
          {
            required: true,
            message: "请输入确认密码",
            trigger: "blur",
          },
        ],
      },
      // formLabelWidth: "120px",
      multipleSelection: [],
      roleTotals: 50,
      //查询
      Keyword: "",
      RoleId: "",
      LoginBeginTime: "",
      LoginEndTime: "",
      editDialogFormVisible: false,
      editform: {
        id: "",
        rechargeCount: "",
      },
    };
  },
  watch: {
    editDialogFormVisible() {
      if (!this.editDialogFormVisible) {
        this.editform.id = "";
        this.editform.rechargeCount = "0";
      }
    },
    editDialogFormVisible1() {
      if (!this.editDialogFormVisible1) {
        this.editUserList.id = "";
        (this.editUserList.realName = ""),
          (this.editUserList.userName = ""),
          (this.editUserList.roleIds = []);
      }
    },
  },
  computed: {
    Userlist() {
      return this.userlist;
    },
    ...mapState(["OrganistationList"]),
    Userlist() {
      var aa = this.userlist.map((item) => {
        item.roleInfoName = item.roleInfoDtos
          .map((name) => {
            return name.roleName;
          })
          .join("、");
        var index;
        var index = this.OrganistationList.findIndex((i) => {
          return i.code == item.organisationCode;
        });
        index == "-1"
          ? (item.ogName = "")
          : (item.ogName = this.OrganistationList[index].name);

        return item;
      });
      return aa;
    },
  },
  created() {
    this.roleInfo = this.$cookies.get("USER").roleInfoDtos;
    // this.setRolesList(this.roleInfo);
    this.GetUser();
    this.GetOrganistationList();
  },
  methods: {
    // 根据登录账号判断角色
    // setRolesList(roles) {
    //   console.log(roles);
    //   if (roles.map((v) => v.roleTypeInfo).includes(3))
    //     this.roles = [
    //       {
    //         roleTypeInfo: 1,
    //         roleName: "用户",
    //       },
    //     ];
    //   if (roles.map((v) => v.roleTypeInfo).includes(4))
    //     this.roles = [
    //       {
    //         roleTypeInfo: 1,
    //         roleName: "用户",
    //       },
    //       {
    //         roleTypeInfo: 2,
    //         roleName: "后台管理员",
    //       },
    //       {
    //         roleTypeInfo: 3,
    //         roleName: "机构管理员",
    //       },
    //     ];
    // },
    // 添加用户取消
    cancelAddUser() {
      this.addDialogFormVisible = false;
      this.$refs.addform.resetFields();
    },
    // 添加用户确认
    confirmAddUser() {
      this.$refs.addform.validate((valid) => {
        if (valid) {
          this.$request({
            url: "/api/userrole/user-manage",
            method: "POST",
            data: JSON.stringify(this.addform),
          })
            .then(async (res) => {
              this.PageIndex = 1;
              await this.GetUser();
              this.addDialogFormVisible = false;
              return this.$message.success("添加成功");
            })
            .catch((e) => {
              //return this.$message.error("服务器异常~");
            });
        }
      });
    },
    // 添加用户
    addUser() {
      // this.GetRole();
      this.addDialogFormVisible = true;
    },
    //请求机构列表
    GetOrganistationList() {
      if (this.OrganistationList.length > 0) return;
      this.$request({
        url: "/api/userrole/organistation",
        method: "GET",
        params: {
          PageSize: this.PageSize,
          PageIndex: 1,
          IsNoPage: true,
          sorting: "Sort",
        },
      })
        .then((res) => {
          this.$store.commit("GetOrganistationList", res.items);
        })
        .catch((e) => {
          //return this.$message.error("服务器异常~");
        });
    },
    //请求角色列表
    // GetRole() {
    //   this.$request({
    //     url: "/api/userrole/role",
    //     method: "GET",
    //     params: {
    //       PageSize: this.roleTotals,
    //       PageIndex: 1,
    //       IsNoPage: true,
    //     },
    //   })
    //     .then((res) => {
    //       this.roleOptions = res.items;
    //       console.log(res.items);
    //     })
    //     .catch((e) => {
    //       //return this.$message.error("服务器异常~");
    //     });
    // },
    //编辑用户角色信息
    roleIdChang() {
      console.log("roleID", this.editform.roleIds);
    },
    // 确认
    async onConfirm(id) {
      await this.ChangUserStatus(
        id,
        JSON.stringify({
          id,
          updateUserInfoType: 1,
          organisationCode: this.editUserList.organisationCode,
          roleIds: this.editUserList.roleIds,
          realName: this.editUserList.realName,
        })
      );
      this.editDialogFormVisible1 = false;
      this.editUserList.id = "";
      this.editUserList.realName = "";
      this.editUserList.userName = "";
      this.editUserList.roleIds = [];
    },
    // 获取用户详情
    eidtUser(id) {
      // this.GetRole();
      this.editDialogFormVisible1 = true;
      this.$request({
        url: "/api/userrole/user-manage/" + id,
        method: "GET",
      })
        .then((res) => {
          var roleIds = [];
          if (res.roleInfoDtos != null) {
            res.roleInfoDtos.map((item) => {
              roleIds.push(item.id);
            });
          }
          this.editUserList.id = res.id;
          this.editUserList.roleIds = roleIds;
          this.editUserList.realName = res.realName;
          this.editUserList.userName = res.userName;
          this.editUserList.organisationCode = res.organisationCode;
        })
        .catch((e) => {});
    },
    //搜索
    searchClick() {
      this.PageIndex = 1;
      this.GetUser();
    },
    //请求用户列表
    GetUser() {
      this.loading = true;
      this.$request({
        url: "/api/userrole/user-center",
        method: "GET",
        params: {
          PageSize: this.PageSize,
          PageIndex: this.PageIndex,
          keyword: this.Keyword,
          RoleId: this.RoleId,
          OrganisationCode: this.OrganisationCode,
          LoginBeginTime: this.LoginBeginTime,
          LoginEndTime: this.LoginEndTime,
        },
      })
        .then((res) => {
          this.loading = false;
          this.userlist = res.items;
          this.Totals = res.totalCount;
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    //改变用户状态
    statusChange(id, status) {
      if (status) {
        this.ChangUserStatus(
          id,
          JSON.stringify({ id, updateUserInfoType: 32 })
        );
      } else {
        this.ChangUserStatus(
          id,
          JSON.stringify({ id, updateUserInfoType: 16 })
        );
      }
    },
    //改变用户状态
    ChangUserStatus(id, data) {
      console.log(data, "data");
      this.$request({
        url: "/api/userrole/user-manage/" + id,
        method: "PUT",
        data: data,
      })
        .then((res) => {
          this.GetUser();
          return this.$message.success("操作成功~");
        })
        .catch((e) => {
          this.GetUser();
          return this.$message.error("操作失败~");
        });
    },
    //重置用户密码
    ResetPassword(id) {
      this.$confirm("此操作将重置该用户密码为888888, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.ChangUserStatus(
            id,
            JSON.stringify({ id, updateUserInfoType: 2 })
          );
        })
        .catch(() => {
          // this.$message({
          //     type: "info",
          //     message: "已取消操作",
          // });
        });
    }, //多选
    handleSelectionChange(val) {
      if (val.length > 0) {
        this.multipleSelection = val;
        this.userid = val[0].id;
        console.log("val", val);
      }
    },
    filterChange(e) {
      console.log("e", e);
    },
    //分页条数
    handleSizeChange(val) {
      this.PageSize = val;
      this.GetUser();
    },
    //分页页数
    handleCurrentChange(val) {
      this.PageIndex = val;
      this.GetUser();
    },
    //请求用户详情
    GetUserDetail(id) {
      this.editDialogFormVisible = true;
      this.editform.id = id;
    },
    //充值识别次数
    EditUserInfo() {
      this.$request({
        url: "/api/userrole/user-manage/recharge",
        method: "POST",
        data: JSON.stringify(this.editform),
      })
        .then((res) => {
          this.PageIndex = 1;
          this.GetUser();
          this.editDialogFormVisible = false;

          return this.$message.success("充值成功");
        })
        .catch((e) => {
          //return this.$message.error("服务器异常~");
        });
    },
  },
};
</script>

<style lang="less" scoped>
.account {
  box-sizing: border-box;
}

.title {
  padding: 10px 20px;
  box-sizing: border-box;
  display: flex;
  border-bottom: 1px solid #eee;
  background-color: #fff;

  .updata,
  .add,
  .inp,
  .search {
    height: 38px;
    line-height: 38px;
    padding: 0 18px;
    white-space: nowrap;
    text-align: center;
    font-size: 14px;
    border-radius: 2px;
    box-sizing: border-box;
    cursor: pointer;
    color: #fff;
    opacity: 1;
    transition: all 0.3s;
  }

  .updata {
    background-color: #ff5722;
  }

  .updata:hover {
    opacity: 0.7;
    transition: all 0.3s;
  }

  .add {
    margin-left: 10px;
    background-color: #009688;
  }

  .add:hover {
    opacity: 0.7;
    transition: all 0.3s;
  }

  .search {
    background-color: #009688;
  }

  .search:hover {
    opacity: 0.7;
    transition: all 0.3s;
  }
}

.table {
  
  box-sizing: border-box;
  background-color: #fff;

  .el-image {
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }

  .edit,
  .role {
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    font-size: 12px;
    background-color: #009688;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    opacity: 1;
    transition: all 0.3s;
  }

  .appcode {
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    font-size: 12px;
    background-color: #1e9fff;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    opacity: 1;
    transition: all 0.3s;
  }

  .delete {
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    font-size: 12px;
    background-color: #ff5722;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    opacity: 1;
    transition: all 0.3s;
  }

  .cancel {
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    font-size: 12px;
    background-color: #ffb800;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    opacity: 1;
    transition: all 0.3s;
  }

  .edit:hover {
    opacity: 0.7;
    transition: all 0.3s;
  }

  .role:hover {
    opacity: 0.7;
    transition: all 0.3s;
  }

  .delete:hover {
    opacity: 0.7;
    transition: all 0.3s;
  }
}

@media screen and(max-width: 1300px) {
  /deep/ .el-card__body {
    padding: 10px;
  }

  .title {
    /deep/ .el-button {
      padding: 6px 10px !important;
    }
  }
}
</style>>
